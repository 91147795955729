<template>
  <div class="edit-input">
    <label>
      <slot name="label"></slot>
    </label>
    <div class="edit-input__header" :class="{'selected': visible}" @click="toggleEdit">
      <slot name="placeholder"></slot>
    </div>
    <div
        v-if="visible"
        class="edit-input__body"
        :class="chevron + ' ' + position + ' ' + align"
        :style="{'--margin': margin}"
    >
      <div class="edit-input__body__controls">
        <slot name="content"></slot>
      </div>
      <div class="edit-input__body__actions">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import {bus} from "@/helpers/bus";

export default {
  name: "edit-input",
  props: {
    position: {
      type: String,
      enum: ["top", "right", "bottom", "left"],
      default: "top"
    },
    align: {
      type: String,
      enum: ["al-start", "al-center", "al-end"],
      default: "al-start"
    },
    type: {type: String, enum: ["list", "icon-list"], default: "list"},
    data: {type: Array, default: () => []},
    chevron: {
      type: String,
      enum: ["top-start", "top-end", "bottom-start", "bottom-end", "none"],
      default: "none"
    },
    margin: String,
    onSelectOption: Function
  },
  data() {
    return {
      visible: false,
      ref: this.randomString(3),
    };
  },

  methods: {
    select(item) {
      if (this.onSelectOption) this.onSelectOption(item);
    },

    toggleEdit() {
      bus.$emit("HIDE_ALL_MODALS", this.ref);
      this.visible = !this.visible;
      this.$emit("reset");
    },

    randomString(length) {
      let text = "",
          possible = "abcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    }
  },

  created() {
    bus.$on("HIDE_ALL_MODALS", ref => {
      if (this.ref !== ref) {
        this.visible = false;
      }
    });
  },
};
</script>

<style scoped lang="scss">

$margin: "10px";

.edit-input {
  position: relative;
  min-width: 20px;
  min-height: 20px;
  width: 100%;
  display: inline-block;

  label {
    text-transform: uppercase;
    font-size: .6rem;
    color: color(bg-black3);
    margin-bottom: 3px;

    div {
      font-weight: $font-medium;
    }
  }

  &__header {
    position: relative;
    width: 100%;
    min-height: 35px;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    padding: 5px 0;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      color: color(bg-black2);
      width: 100%;

      span {
        font-weight: $font-bold;
        font-size: .9rem;
      }

      i {
        display: none;
      }
    }

    &:hover {
      border: 1px solid color(border);
      background: color(bg-gray3);
      font-weight: $font-medium;
      padding: 5px 10px;
      border-radius: 5px;

      i {
        display: inline-block;
      }
    }

    &.selected {
      background: color(bg-gray3);
      border-radius: 5px;
      padding: 5px 10px;
      border: 1px solid color(bg-primary);

      span {
        font-weight: $font-bold;
      }

      div {
        color: color(bg-primary);
      }

      i {
        display: inline-block;
      }
    }
  }

  &__body {
    background: color(bg-fill);
    border: 1px solid color(bg-primary);
    border-radius: 3px;
    width: 100%;
    display: flex;
    position: absolute;
    flex-direction: column;
    --margin: #{$margin};
    z-index: 99999;

    h2 {
      border-bottom: 1px solid color(border);
      background: color(bg-white);
      border-radius: 3px 3px 0 0;
      font-size: 0.7rem;
      font-weight: $font-medium;
      display: flex;
      color: color(bg-black2);
      align-items: center;
      padding: 0 12px;
      height: 35px;
      width: 100%;
    }

    &__controls {
      flex: 1;

      .controls {
        padding: 12px;
      }
    }

    &__actions {
      border-top: 1px solid color(border);
      background: white;
      padding: 0 12px;
      border-radius: 0 0 3px 3px;

      .actions {
        display: grid;
        grid-template-columns: repeat(2, auto);
        justify-items: end;
        justify-content: end;
        gap: 10px;
        padding: 10px 0;
      }
    }

    &.top-start,
    &.top-end,
    &.bottom-start,
    &.bottom-end {
      &:before {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        border: 1px solid color(border);
        border-right: none;
        border-bottom: none;
        border-top-left-radius: 3px;

        background: white;
      }
    }

    &.top-start {
      &:before {
        transform: rotate(45deg);
        left: 10px;
        top: -5.5px;
      }
    }

    &.top-end {
      &:before {
        transform: rotate(45deg);
        right: 10px;
        top: -5.5px;
      }
    }

    &.bottom-start {
      &:before {
        transform: rotate(-135deg);
        left: 10px;
        bottom: -5.5px;
      }
    }

    &.bottom-end {
      &:before {
        transform: rotate(-135deg);
        right: 10px;
        bottom: -5.5px;
      }
    }

    .chevron {
      width: 20px;
      height: 20px;
    }

    &.top {
      bottom: 100%;
      margin-bottom: var(--margin);

      &.al-start {
        left: 0;
      }

      &.al-end {
        right: 0;
      }
    }

    &.right {
      left: 100%;
      margin-left: var(--margin);

      &.al-start {
        top: 0;
      }

      &.al-end {
        bottom: 0;
      }
    }

    &.bottom {
      top: 100%;
      margin-top: var(--margin);

      &.al-start {
        left: 0;
      }

      &.al-end {
        right: 0;
      }
    }

    &.left {
      right: 100%;
      margin-right: var(--margin);

      &.al-start {
        top: 0;
      }

      &.al-end {
        bottom: 0;
      }
    }
  }
}
</style>
