<template>
  <section class="html5editor">
    <textarea ref="editor" style="visibility: hidden" v-model="editorData" @input="updateText"></textarea>
    <ckeditor v-model="editorData" :config="editorConfig" @input="updateText"></ckeditor>

    <span v-if="error" class="has-error animated headShake">{{ error }}</span>
    <div v-if="autocompleteLib.length > 0" class="autocomplete">
      <div class="separator"/>
      <div class="item" v-for="item in autocompleteLib.slice(0,3)" :data-text="item.text"
           @click="insertAutoComplete(item.value)" :key="`autocomplete_text_${item.text}`">
        <font-awesome-icon v-if="typeof item.icon == 'object' " :icon="item.icon"/>
        <i v-else :class="item.icon"/>
      </div>
      <div v-if="autocompleteLib.length > 3" class="more" @click="toggleMoreIcons" v-click-outside="hideMoreIcons">
        <font-awesome-icon class=".icon" title="Insert more..." :icon="['fas', 'chevron-double-right']"/>
        <div v-if="showMore" class="more__container">
          <div class="box" v-for="item in autocompleteLib.slice(3,autocompleteLib.length)"
               :key="`autocomplete_value_${item.value}`">
            <div class="item" :data-text="item.text" @click="insertAutoComplete(item.value)">
              <font-awesome-icon v-if="typeof item.icon == 'object' " :icon="item.icon"/>
              <i v-else :class="item.icon"/>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CKEditor from 'ckeditor4-vue';

export default {
  name: "HtmlEditor",
  components: {
    ckeditor: CKEditor.component
  },

  props: {
    text: {type: String, default: ''},
    change: {type: Function},
    value: {type: String, default: ''},
    click: {type: Function},
    autocompleteLib: {
      type: Array, default: function () {
        return [];
      }
    },
    error: {type: String},
  },

  data() {
    return {
      showMore: false,
      editorData: '',
      editorConfig: {
        fullPage: true,
        allowedContent: true,
        disallowedContent: 'script; *[on*]',
        baseFloatZIndex: 99999999,
        removeButtons: "Maximize",
        toolbarGroups: [
          {
            "name": "basicstyles",
            "groups": ["cleanup"]
          },
          {
            "name": "links",
            "groups": ["links"]
          },
          {
            "name": "paragraph",
            "groups": ["list", "blocks"]
          },
          {
            "name": "document",
            "groups": ["mode"]
          },
        ]
      }
    };
  },

  methods: {
    hideMoreIcons() {
      this.showMore = false
    },
    toggleMoreIcons() {
      this.showMore = !this.showMore;
    },
    setEditorData(data) {
      this.editorData = data;
    },
    insertAutoComplete(value) {

      const p = document.createElement("p");
      p.innerHTML = this.editorData.substring(3, this.editorData.length - 4) + ` ${value}`;

      this.$emit('input', p.innerHTML);
      if (this.change) this.change(this.$refs.editor.value);
    },
    updateText() {
      this.$emit('input', this.editorData);
      if (this.change) this.change(this.$refs.editor.value);
    },
    save() {
      this.$emit('save');
    },
    close() {
      this.$emit('close');
    }
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.editorData = this.value;
      }
    }
  }
}
</script>

<style lang="scss">

.html5editor {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;

  & > div {
    flex: 1;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;

    & > div {
      height: 100%;
      display: flex;
      flex-direction: column;

      .cke_inner {
        display: flex;
        flex: 1;
        flex-direction: column;

        .cke_contents {
          flex: 1;
        }

        .cke_bottom {
          //display: none;
        }
      }
    }
  }

  .autocomplete {
    position: absolute;
    width: 200px;
    height: 30px;
    right: 20px;
    top: 5px;
    display: flex;
    align-items: center;

    .separator {
      border-left: 1.3px solid color(border);
      margin-right: 10px;
      height: 20px;
    }

    .item, & > .more {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      cursor: pointer;
      margin-right: 5px;
      position: relative;

      &:hover {
        background: rgba(228, 233, 241, 0.97);
      }

      i, .icon {
        font-size: 1.4em;
        color: color(bg-black1);
      }
    }

    .item {
      &:hover {
        &:after {
          content: attr(data-text);
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          top: 38px;
          font-size: .8em;
          color: white;
          padding: 5px;
          background: color(bg-black2);
          text-align: center;
          transition: all .2s ease-in-out;
          border-radius: 3px;
        }

        &:before {
          content: "";
          position: absolute;
          top: 30px;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 16px solid color(bg-black2);;
        }
      }

    }

    .more__container {
      position: absolute;
      width: 200px;
      max-height: 300px;
      border: 1px solid color(border);
      background: white;
      border-radius: 5px;
      top: 30px;
      right: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: flex-start;

      .box {
        width: 100%;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .has-error {
    position: absolute;
    bottom: -14px;
    font-size: .7rem;
    right: 5px;
    color: color(bg-red);
    font-weight: $font-medium;
  }
}
</style>
